<template>
	<div class="bg-white flex flex-row justify-between p-5">
		<TengivaLogo
			color="black"
			class="h-[30px] mt-[8px] ml-[2%]"
		/>
		<Button
			color="black"
			class="mr-[2%]"
			height="45"
			width="200"
			size="large"
			right-icon="md-chevron_right"
			@click="goToTengiva"
		>
			<span class="ml-[10px]"><span class="mobile:hidden">Go to</span> Tengiva.com</span>
		</Button>
	</div>
</template>

<script setup lang="ts">
import TengivaLogo from "../TengivaLogo.vue";
// import Icon from "@/components/Icon/index.vue";

const goToTengiva = () => {
	window.open("https://tengiva.com");
};
</script>
